import scrollMonitor from 'scrollmonitor';
const blocchi = document.querySelectorAll('[data-blocco]');

blocchi.forEach((blocco) => {
    let image = blocco.querySelector('img');
    let title = blocco.querySelector('h2');
    let text = blocco.querySelector('p');
    let half = blocco.clientHeight / 2;
    let bloccoWatcher = scrollMonitor.create(blocco, half * -1);
    bloccoWatcher.enterViewport(function () {
        image.classList.remove('opacity-0');
        title.classList.remove('opacity-0', 'translate-y-5');
        text.classList.remove('opacity-0', 'translate-y-5');
    });
    // bloccoWatcher.exitViewport(function () {
    //     image.classList.add('opacity-0');
    //     title.classList.add('opacity-0', 'translate-y-5');
    //     text.classList.add('opacity-0', 'translate-y-5');
    // });
});

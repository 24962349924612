import scrollMonitor from 'scrollmonitor';
const sections = document.querySelectorAll('[data-section="focus"]');

// Funzione per rimuovere le classi con un ritardo crescente
function removeClassesWithDelay(elements, classes, initialDelay, staggerDelay) {
    elements.forEach((el, index) => {
        const delay = initialDelay + staggerDelay * index;
        setTimeout(() => {
            classes.forEach((className) => {
                el.classList.remove(className);
            });
        }, delay);
    });
}

// Definisci il ritardo iniziale e lo "stagger" delay
const initialDelay = 0;
const staggerDelay = 150; // Ritardo tra l'animazione di ciascun elemento

sections.forEach((section) => {
    let sectionWatcher = scrollMonitor.create(section, {top: -300, bottom:0});
    let focuses = section.querySelectorAll('[data-focus-point]');
    sectionWatcher.enterViewport(function () {
        removeClassesWithDelay(
            focuses,
            ['translate-y-5', 'opacity-0'],
            initialDelay,
            staggerDelay
        );
    });
    // sectionWatcher.exitViewport(() => {
    //     focuses.forEach((focus) => {
    //         focus.classList.add('translate-y-5', 'opacity-0');
    //     });
    // });
});

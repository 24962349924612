import './components/resize';
import './components/animablocchi';
import './components/nav';
import './components/anchorscroll';
import './components/modal';
import './components/animate-focus-points';

// Rimozione classe no-js
document.documentElement.className = document.documentElement.className.replace(
    /\bno-js\b/,
    'js'
);

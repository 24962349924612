function smoothScroll(target, additionalOffset) {
    const element = document.getElementById(target);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - additionalOffset,
            behavior: 'smooth',
        });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach(function (anchor) {
        anchor.addEventListener('click', function (event) {
            event.preventDefault();
            const target = anchor.getAttribute('href').substring(1);
            const additionalOffset = anchor.getAttribute('data-scroll-offset');
            smoothScroll(target, additionalOffset);
        });
    });
});

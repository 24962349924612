import anime from 'animejs';

const hamburger = document.querySelector('[data-hamburger]');
const navList = document.querySelector('[data-navList]');
const closer = document.querySelector('[data-nav-closer]');

var menuOpen = false;

hamburger.addEventListener('click', () => {
    var openTl = anime.timeline({
        easing: 'easeOutSine',
        duration: 400,
    });
    openTl.add({
        targets: navList,
        translateX: ['-100%', '0%'],
    });
    openTl.add({
        targets: '[data-real-nav-item]',
        translateY: [40, '0%'],
        opacity: [0, 1],
        delay: anime.stagger(100),
    });
    menuOpen = true;
});

closer.addEventListener('click', () => {
    anime({
        targets: navList,
        translateX: '-100%',
        easing: 'easeOutSine',
        duration: 400,
        complete: function (anim) {
            console.log(anim.animatables[0].target.removeAttribute('style'));
        },
    });
    menuOpen = false;
});

function openModal(modalName) {
    const modals = document.querySelectorAll(
        '[data-modal="' + modalName + '"]'
    );
    if (modals) {
        modals.forEach((modal) => {
            const modalChild = modal.querySelector('[data-modal-body]');
            modal.setAttribute('data-modal-open', true);
            modal.classList.remove('hidden');
            setTimeout(() => {
                modal.classList.remove('opacity-0');
                modal.classList.add('flex', 'opacity-100');
                modalChild.classList.remove('translate-y-5');
                modalChild.classList.add('translate-y-0');
            }, 5);
        });
    }
}

function closeModal() {
    const modal = document.querySelector('[data-modal-open]');
    const modalChild = modal.querySelector('[data-modal-body]');
    modal.classList.remove('opacity-100');
    modal.classList.add('opacity-0');
    modal.addEventListener(
        'transitionend',
        () => {
            modal.classList.remove('flex');
            modal.classList.add('hidden');
            modalChild.classList.remove('translate-y-0');
            modalChild.classList.add('translate-y-5');
            modal.removeAttribute('data-modal-open');
        },
        { once: true }
    );
}

// Click sui trigger
document.addEventListener('DOMContentLoaded', function () {
    const modalTriggers = document.querySelectorAll('[data-modal-trigger]');
    modalTriggers.forEach((trigger) => {
        const modalName = trigger.getAttribute('data-modal-trigger');
        trigger.addEventListener('click', function () {
            openModal(modalName);
        });
    });
    const closers = document.querySelectorAll('[data-modal-closer]');
    closers.forEach((closer) => {
        closer.addEventListener('click', () => {
            closeModal();
        });
    });
});

// Click sul darkener
document.addEventListener('click', function (e) {
    var targetElement = e.target;
    const modalOpen = document.querySelector('[data-modal-open]');
    if (modalOpen) {
        if (targetElement == modalOpen) {
            closeModal();
        }
    }
});
